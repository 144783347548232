import { Link, useLocation, useNavigate, useNavigation } from '@remix-run/react';
import { type ReactElement, useEffect, useState } from 'react';
import Logo from '~/images/logo.svg';
import SmallLogo from '~/images/cd_logo.svg';
import { type IUserInfo } from 'types/mylaurel/IUserInfo';
import { CreateOrUpdatePatientFormModal } from '~/routes/__dashboard/components/Patients/CreateOrUpdatePatientFormModal';
import {
  LucideCalendar,
  LucideCircleFadingPlus,
  LucideHeartHandshake,
  LucideTicket,
  LucideUndo,
  LucideUserRoundSearch,
} from 'lucide-react';
import LoadingSpinner from '~/components/LoadingSpinner';
import Tooltip from '~/components/tailgrids/Tooltip';
import ConditionalWrapper from '~/components/ConditionalWrapper';
import { EpisodeStatusIndicator } from '~/components/EpisodeStatusIndicator';

interface HeaderNavigationProps {
  user: IUserInfo | undefined;
  partnerNames: string[];
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}

export default function Navigation({
  user,
  partnerNames,
  isCollapsed,
  setIsCollapsed,
}: HeaderNavigationProps): ReactElement {
  const [showEditPatientModal, setShowEditPatientModal] = useState<boolean>(false);
  const defaultAccess = ['admin', 'managers'];
  const navigationState = useNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownsOpen, setDropdownsOpen] = useState<Set<string>>(new Set(['Episodes']));
  const allAccess = [...defaultAccess, 'pex', 'rncm', 'providers'];
  const navigationAndRoles = [
    {
      name: 'Appointments',
      href: '/appointments',
      allowedGroups: allAccess,
      dropdown: false,
      dropdownOptions: [],
    },
    { name: 'Actions', href: '/actions', allowedGroups: allAccess, dropdown: false, dropdownOptions: [] },
    {
      name: 'Episodes',
      href: '',
      allowedGroups: allAccess,
      dropdown: true,
      dropdownOptions: [
        {
          name: 'Referred',
          href: '/episodes?episodeStatus=referred',
        },
        {
          name: 'Pending',
          href: '/episodes?episodeStatus=pending',
        },
        {
          name: 'Active',
          href: '/episodes?episodeStatus=active',
        },
        {
          name: 'Closed',
          href: '/episodes?episodeStatus=closed',
        },
      ],
    },
    {
      name: 'Patient Search',
      href: '/patients',
      allowedGroups: allAccess,
      dropdown: false,
      dropdownOptions: [],
    },
    {
      name: 'Engagements',
      href: '/engagements',
      allowedGroups: allAccess,
      dropdown: false,
      dropdownOptions: [],
    },
  ];

  const archiveDetails = {
    name: 'Archive',
    allowedGroups: allAccess,
    dropdown: true,
    dropdownOptions: [
      {
        name: 'All Referrals',
        shortName: 'Referrals',
        href: '/referrals',
      },
      { name: 'All Actions', shortName: 'Actions', href: '/actions' },
    ],
  };

  useEffect(() => {
    if (isCollapsed) {
      setDropdownsOpen(new Set(['Episodes', 'Archive']));
    } else {
      setDropdownsOpen(new Set(['Episodes']));
    }
  }, [isCollapsed]);

  const navigation = navigationAndRoles.filter(item =>
    user?.groups?.some((group: string) => item.allowedGroups.includes(group)),
  );
  const getHeaderIcon = (header: string): ReactElement | null => {
    switch (header.toLowerCase()) {
      case 'appointments':
        return <LucideCalendar className={`stroke-[1.5px] ${isCollapsed ? 'w-7 h-7' : 'w-[18px] h-[18px] mr-2'}`} />;
      case 'episodes':
        return (
          <LucideCircleFadingPlus className={`stroke-[1.5px] ${isCollapsed ? 'w-7 h-7' : 'w-[18px] h-[18px] mr-2'}`} />
        );
      case 'patient search':
        return (
          <LucideUserRoundSearch className={`stroke-[1.5px] ${isCollapsed ? 'w-7 h-7' : 'w-[18px] h-[18px] mr-2'}`} />
        );
      case 'actions':
        return <LucideTicket className={`stroke-[1.5px] ${isCollapsed ? 'w-7 h-7' : 'w-[18px] h-[18px] mr-2'}`} />;
      case 'engagements':
        return (
          <LucideHeartHandshake className={`stroke-[1.5px] ${isCollapsed ? 'w-7 h-7' : 'w-[18px] h-[18px] mr-2'}`} />
        );
      case 'archive':
        return <LucideUndo className={`stroke-[1.5px] ${isCollapsed ? 'w-7 h-7' : 'w-[18px] h-[18px] mr-2'}`} />;
      default:
        return null;
    }
  };

  const isPageLoading = (href: string): boolean =>
    navigationState?.location?.pathname + (navigationState?.location?.search ?? '') === href;

  const handleDropdownClick = (name: string): void => {
    const newDropdownsOpen = new Set(dropdownsOpen);
    if (newDropdownsOpen.has(name)) {
      newDropdownsOpen.delete(name);
    } else {
      newDropdownsOpen.add(name);
    }
    setDropdownsOpen(newDropdownsOpen);
  };

  return (
    <div className="flex w-full h-full">
      {showEditPatientModal ? (
        <CreateOrUpdatePatientFormModal
          partnerNames={partnerNames || ''}
          isOpen={showEditPatientModal}
          onHide={() => {
            setShowEditPatientModal(false);
          }}
          className="overflow-y-auto h-full w-full"
        />
      ) : null}

      <div className="flex flex-col w-full h-full justify-between z-51">
        <div className="flex flex-col w-full">
          <Tooltip
            tooltipsText={isCollapsed ? 'Expand' : 'Collapse'}
            position={'right'}
            className="border-r-[5px] border-[#5CE6E6] z-51"
          >
            <div
              className={
                'flex w-full items-center justify-center z-51 border-r-[5px] border-transparent hover:border-[#5CE6E6] hover:bg-[#6366F1] hover:bg-opacity-50'
              }
            >
              {isCollapsed ? (
                <button
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                  className="pt-4 pl-5 -ml-4 pb-7"
                >
                  <img src={SmallLogo} alt="caredash" className="w-10" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                  className="inline-flex justify-center items-center pb-4 pl-8 -ml-4"
                >
                  <img src={Logo} alt="caredash" className="w-[130px]" />
                </button>
              )}
            </div>
          </Tooltip>
          <div className="flex flex-col -ml-1.5">
            {navigation.map(item => (
              <div key={item.href} className="flex flex-col">
                {
                  <ConditionalWrapper
                    condition={isCollapsed}
                    wrapper={children => (
                      <Tooltip tooltipsText={item.name} position={'right'} className="border-r-[5px] border-[#5CE6E6]">
                        {children}
                      </Tooltip>
                    )}
                  >
                    <div
                      className={`flex ${isCollapsed ? 'justify-center' : 'pl-4'} border-r-[5px] hover:border-[#5CE6E6] hover:bg-[#6366F1] hover:bg-opacity-50 ${location.pathname === item.href ? 'border-[#5CE6E6] bg-[#6366F1] bg-opacity-50' : 'border-transparent'}`}
                    >
                      <div className="flex items-center justify-between w-full">
                        {item.href ? (
                          <Link
                            to={item.href}
                            className={`${
                              location.pathname === item.href
                                ? 'text-[#5CE6E6] hover:text-[#5CE6E6]'
                                : 'text-white hover:text-[#5CE6E6]'
                            } inline-flex items-center p-3 text-base font-bold w-fit gap-1 ${isCollapsed ? 'ml-4' : ''}`}
                          >
                            <span className={'text-[#5CE6E6]'}>{getHeaderIcon(item.name)}</span>
                            <span className={`text-[14px] ${isCollapsed ? 'hidden' : 'flex'}`}>{item.name}</span>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              handleDropdownClick(item.name ?? '');
                            }}
                            className={`text-white hover:text-[#5CE6E6] inline-flex items-center p-3 text-base font-bold w-fit gap-1 ${isCollapsed && item.dropdown ? 'ml-4' : ''}`}
                          >
                            <span
                              className={`${location.pathname === item.href ? 'text-indigo-600' : 'text-[#5CE6E6]'}`}
                            >
                              {getHeaderIcon(item.name)}
                            </span>
                            <span className={`text-[14px] ${isCollapsed ? 'hidden' : 'flex'}`}>{item.name}</span>
                          </button>
                        )}
                        <div
                          className={`px-[0.150rem] inline-block absolute ${isCollapsed ? 'left-1' : 'right-2'} ${
                            isPageLoading(item.href) ? `opacity-100` : `opacity-0`
                          }`}
                        >
                          <LoadingSpinner />
                        </div>
                      </div>
                    </div>
                  </ConditionalWrapper>
                }
                {item.dropdown && dropdownsOpen.has(item.name) ? (
                  <div className={`flex flex-col gap-2 mb-4 ${isCollapsed ? 'items-center ml-3' : 'ml-[3.6rem]'}`}>
                    {item.dropdownOptions.map(option => {
                      return (
                        <ConditionalWrapper
                          key={option.name}
                          condition={isCollapsed}
                          wrapper={children => (
                            <Tooltip
                              tooltipsText={option.name}
                              position={'right'}
                              className="border-r-[5px] border-[#5CE6E6]"
                            >
                              {children}
                            </Tooltip>
                          )}
                        >
                          <div className="flex justify-between w-full">
                            <button
                              key={option.name}
                              className={`flex items-center gap-1.5 font-medium text-nowrap text-[12px]`}
                              onClick={() => {
                                navigate(option.href);
                              }}
                            >
                              {item?.name?.toLowerCase()?.includes('episode') ? (
                                <div className="w-[16px] h-[16px] flex items-center justify-center -mt-[1.5px]">
                                  <EpisodeStatusIndicator episodeStatus={option.name} enableToolTip={false} isNavBar />
                                </div>
                              ) : null}{' '}
                              <span
                                className={
                                  location.pathname + (location.search ?? '') === option.href
                                    ? 'text-[#5CE6E6] hover:text-[#5CE6E6] font-extrabold border-b-[2.5px] border-[#3F8BFF]'
                                    : 'text-gray-300 hover:text-[#5CE6E6]'
                                }
                              >
                                {isCollapsed ? null : option.name}
                              </span>
                            </button>
                            <div
                              className={`px-[0.150rem] inline-block absolute ${isCollapsed ? 'left-4' : 'right-2'} ${
                                isPageLoading(option.href) ? `opacity-100` : `opacity-0`
                              }`}
                            >
                              <LoadingSpinner />
                            </div>
                          </div>
                        </ConditionalWrapper>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          <div>
            <div key={archiveDetails.name} className="flex flex-col -ml-1.5">
              {
                <ConditionalWrapper
                  condition={isCollapsed}
                  wrapper={children => (
                    <Tooltip
                      tooltipsText={archiveDetails.name}
                      position={'right'}
                      className="border-r-[5px] border-[#5CE6E6]"
                    >
                      {children}
                    </Tooltip>
                  )}
                >
                  <div
                    className={`flex ${isCollapsed ? 'justify-center' : 'pl-4'} border-r-[5px] hover:border-[#5CE6E6] hover:bg-[#6366F1] hover:bg-opacity-50 border-transparent`}
                  >
                    <div className="flex w-full items-center justify-between">
                      <button
                        onClick={() => {
                          handleDropdownClick(archiveDetails.name ?? '');
                        }}
                        className={`text-white hover:text-[#5CE6E6] inline-flex items-center p-3 text-base font-bold w-fit gap-1 ${isCollapsed && archiveDetails.dropdown ? 'ml-4' : ''}`}
                      >
                        <>
                          <span className={'text-[#5CE6E6]'}>{getHeaderIcon(archiveDetails.name)}</span>
                          <span className={`text-[14px] ${isCollapsed ? 'hidden' : 'flex'}`}>
                            {archiveDetails.name}
                          </span>
                        </>
                      </button>
                    </div>
                  </div>
                </ConditionalWrapper>
              }
              {archiveDetails.dropdown && dropdownsOpen.has(archiveDetails.name) ? (
                <div className={`flex flex-col gap-1 ${isCollapsed ? 'justify-center ml-4' : 'ml-[3.7rem]'}`}>
                  {archiveDetails.dropdownOptions.map(option => {
                    return (
                      <ConditionalWrapper
                        key={option.name}
                        condition={isCollapsed}
                        wrapper={children => (
                          <Tooltip
                            tooltipsText={option.name}
                            position={'right'}
                            className="border-r-[5px] border-[#5CE6E6]"
                          >
                            {children}
                          </Tooltip>
                        )}
                      >
                        <div className="flex w-full justify-between">
                          <button
                            key={option.name}
                            className={`${
                              location.pathname + (location.search ?? '') === option.href
                                ? 'text-[#5CE6E6] hover:text-[#5CE6E6]'
                                : 'text-gray-300 hover:text-[#5CE6E6]'
                            } flex items-center gap-1.5 text-[12px] font-medium text-nowrap`}
                            onClick={() => {
                              navigate(option.href);
                            }}
                          >
                            {archiveDetails?.name?.toLowerCase()?.includes('episode') ? (
                              <EpisodeStatusIndicator episodeStatus={option.name} enableToolTip={false} />
                            ) : null}{' '}
                            <span
                              className={
                                location.pathname + (location.search ?? '') === option.href
                                  ? 'text-[#5CE6E6] hover:text-[#5CE6E6] font-extrabold border-b-[2.5px] border-[#3F8BFF]'
                                  : 'text-gray-300 hover:text-[#5CE6E6]'
                              }
                            >
                              {isCollapsed ? option.shortName : option.name}
                            </span>
                          </button>
                          <div
                            className={`px-[0.150rem] inline-block absolute ${isCollapsed ? 'left-1' : 'right-2'} ${
                              isPageLoading(option.href) ? `opacity-100` : `opacity-0`
                            }`}
                          >
                            <LoadingSpinner />
                          </div>
                        </div>
                      </ConditionalWrapper>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
